








import { Component, Vue } from 'vue-property-decorator';
import { NeWidgetCookiesPolicy } from '@ne/ne-vue2-lib';
import { mneApiCookiesPath } from '@/api/config';

export interface MneCookiesResponse {
  data: {
    data: {
      attributes: {
        updateDate: string;
        value: string;
      };
      id: string;
      type: string;
    };
  };
}

@Component({
  name: 'CookiesPolicy',
  components: {
    NeWidgetCookiesPolicy
  }
})
export default class CookiesPolicy extends Vue {
  policyText = '';

  async created (): Promise<void> {
    const policyResponse = (await this.$apiMNE.get(mneApiCookiesPath)) as MneCookiesResponse;
    this.policyText = policyResponse.data.data.attributes.value;
  }
}
